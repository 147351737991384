import React, { ReactNode } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  content?: ReactNode;
  primary?: boolean;
  inputRef?: any;
}

export default function Button({ content, children, primary, inputRef, ...props }: ButtonProps) {
  return (
    <button {...props} ref={inputRef} data-primary={primary}>
      {content || children}
    </button>
  );
}
