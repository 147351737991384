import React from 'react';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import { graphql, Link } from 'gatsby';
import Button from '../components/Button';
import '../app/fragments';
import '../app/styles.scss';

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const NotFoundPage = ({ data: { heroImage } }) => (
  <article data-theme="form">
    <SEO title="404: Not found" />
    <div className="hero">
      <Img
        imgStyle={{ objectPosition: 'center top%' }}
        className="hero-image"
        fluid={heroImage.childImageSharp.fluid}
      />
      <div className="content page-404">
        <div className="text">
          <h2>404</h2>
          <p>
            It seems the page you
            <br />
            were looking for does not exist.
            <br />
            the squirrel was hungry
          </p>
          <Link to="/">
            <Button primary content="Go Home" />
          </Link>
        </div>
      </div>
    </div>
  </article>
);

export default NotFoundPage;
